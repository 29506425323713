@import '~bulma/bulma.scss'

html, body
  max-width: 100%

.side-area
  max-width: 400px
//  @include tablet
//    margin-left: 2em
//    margin-bottom: 2em

.text-editor
  border-color: rgb(181, 181, 181)
  border-radius: 4px
  border-style: solid
  border-width: 1.25px
  box-shadow: rgba(10, 10, 10, 0.05) 0px 1px 2px 0px inset
  margin-top: 1px

  .toolbar
    margin-bottom: 0
    padding: 0.25em
    background-color: #ececec
    border-bottom: 1.25px solid #acacac

    .field
      margin-bottom: 0

  .editor-content
    padding: .5em
    // TODO: Somewhat copy pasted from bulma, any way to inherit?

  .editor-placeholder
    user-select: none
    pointer-events: none

